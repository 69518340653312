/* CSS Document */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    /*background: gray;*/
    font-family: 'Open Sans', arial, sans-serif;
    font-size: small;
    color: #fff;
    background: #073b9f;
    font-size: 14px;
}

.modal .modal-title,
.modal label {
    color: #000 !important;
}

.margin-all {
    margin: 10px;
}

.margin-tb {
    margin: 20px;
}

.margin-right {
    margin-right: 5px;
}

.margin-bottom {
    margin-bottom: 10px;
}

.padding-all {
    padding: 10px;
}

.padding-none {
    padding: 0;
}

.width-full {
    width: 100%;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    font-family: 'Open Sans', arial, sans-serif;
}

a {
    color: #fff;
}

a:hover {
    text-decoration: none;
}

.white-font {
    color: #fff;
}

.icon {
    margin-top: -18px;
    padding: 0 6px;
}

hr {
    border-top: none;
    border-bottom: 1px solid #2c4f9c;
}

.footer h4 {
    font-size: 14px;
    font-weight: bolder;
}

.videos {
    margin-top: 20px;
}

.videos .panel {
    background: #000;
    border: #000;
}

.video-big {
    height: auto;
    min-height: 300px;
}

.video-small {
    height: 100px;
}

.main-content {
    margin-top: 15px;
}

.home {
    margin-top: 20px;
}

.home h1,
.home p {
    text-align: center;
}

.home p {
    font-size: 14px;
    font-weight: normal;
}

.border-top {
    border-top: 1px solid #162d82;
}

.main-title {
    padding: 20px 0;
}

.top-bar {
    background: #0b1f67;
    border-bottom: 1px solid #0b1f67;
    font-size: 12px;
}

.top-bar .btn-custom {
    margin-top: 3px;
}

.banner {
    background: #051c72;
    height: 122px;
}

.nav-wrapper {
    background: #00114f;
}

.navbar-brand,
.navbar-brand:hover {
    color: #fff;
    text-shadow: 0px 1px 10px #fff;
    font-size: 14px;
}

.logo {
    width: 368px;
    height: 82px;
    background: url(../../images/logo.png) no-repeat;
    margin-top: 20px;
}

.logo-small {
    width: 288px;
    height: 35px;
    background: url(../../images/logo-small.png) no-repeat;
}

.form-row-less {
    height: 45px;
    overflow: hidden;
}

.align-right {
    text-align: right;
}

.footer ul a {
    padding: 3px 5px;
    font-weight: bold;
    color: #fff;
}

.footer ul a:hover {
    background: #11aa1b;

}

.form-custom {
    background: #04205e;
    border: none;
    outline: none;
    color: #fff;
    border-bottom: 1px solid #02102e;
    border: none;
}

.form-custom::-webkit-input-placeholder {
    color: #fff !important;
    font-size: 12px;
}

.copyright {
    text-align: center;
    padding-bottom: 20px;
}

.form-custom:-moz-placeholder {
    /* Firefox 18- */
    color: #fff !important;
    font-size: 12px;
}

.form-custom::-moz-placeholder {
    /* Firefox 19+ */
    color: #fff !important;
    font-size: 12px;
}

.form-custom:-ms-input-placeholder {
    color: #fff !important;
    font-size: 12px;
    color: red;
}

.gold-academy {
    font-size: 13px;
    line-height: 20px;
}

.profile {
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px dashed #353b3f;
}

.profile .thumb {
    text-align: center;
    background: #666666;
    overflow: hidden;
    width: 55px;
    margin: 10px auto;
    height: 55px;
    line-height: 55px;
    border-radius: 99px;
    -webkit-border-radius: 99px;
    -moz-border-radius: 99px;
    border: 3px solid #fff;
}

.profile .thumb:hover {
    border: 3px solid #ccc;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -ms-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}

.profile a {
    color: #999999;
}

.profile i {
    font-size: 11px;
}

.main-wrapper {
    margin: 0;
}

.navigation li a {
    font-size: 14px;
    color: #fff;
    /*font-weight: bolder;*/
}

.navigation li a:hover {
    background: #11aa1b;
}

.navigation li.dropdown.open,
.navigation li.dropdown.open a {
    background-color: #11aa1b !important;
}

.navigation ul.dropdown-menu {
    background: #00114f;
    border-top: 1px solid #11aa1b;
}

.navigation li.dropdown.open .dropdown-menu a {
    background-color: #00114f !important;
    font-size: 13px;
    font-weight: normal;
}

.navigation li.dropdown.open .dropdown-menu a:hover {
    background-color: #11aa1b !important;
}

h1 .glyphicon {
    font-size: 22px;
}

.tab-viewer {
    margin: 0 1px;
}

.tab-viewer .panel {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.tab-viewer .list-group-item span {
    background: orange;
    float: right;
    width: 90%;
}

.row-table {
    padding: 10px;
}

.tab-pane {
    display: none
}

.tab-pane.tab-pane-active {
    display: block
}

.wrapper-min-height {
    min-height: 1000px;
}

.panel-post {
    padding: 2px;
}

.panel-post textarea {
    border: none;
    outline: none !important;
    padding: 5px;
    font-size: 14px;
    width: 100%;
    text-shadow: none;
    resize: none;
    font-weight: normal;
    color: #222;
}

.stream-profile,
.comment-profile {
    font-size: 45px;
    text-align: center;
    background: #f2f2f2;
    color: gray;
    border-radius: 3px;
}

.comment-profile {
    font-size: 28px;
}

.panel-stream hr {
    margin: 18px 0;
    border-top: none;
    border-bottom: 1px dotted #ddd;
}

.panel-custom-wrapper .panel {
    background: #00081b;
}

.panel-custom-wrapper .panel-heading {
    background: #11aa1a;
}

.panel-custom-wrapper .list-group-item {
    background: #00081b;
}

.panel-custom-wrapper .list-group-item {
    background: #00081b;
    border: 1px solid #002168;
}

.panel-custom-wrapper .table {
    background: #00081b;
    border: 1px solid #002168;
}

.panel-custom-wrapper .table-striped > tbody > tr:nth-child(odd) > td, .table-striped > tbody > tr:nth-child(odd) > th {
    background: #333;
    border-top: 1px solid #333;
}

.panel-custom-wrapper .table thead > tr > th {
    border-bottom: none;
}

.panel-custom-wrapper .table thead > tr > th, .table tbody > tr > th, .table tfoot > tr > th, .table thead > tr > td, .table tbody > tr > td, .table tfoot > tr > td {
    border-top: none;
}

.nav-custom {
    background: #04205E;
    padding: 2px;
    border-radius: 6px;
}

.nav-custom span {
    margin-top: -18px;
}

.nav-custom li a {
    color: #fff;
}

.nav-custom li.active a {
    background: #11aa1b;
}

.nav-custom li a:hover, 
.nav-custom li a:focus {
    background: #109818 !important;
}

.toggler a {
    color: #fff;
}

.top-button {
    margin-top: 40px;
}

.btn-custom button {
    /*font-weight: bold;*/
}

.btn-custom .btn-success {
    background: #11aa1b;
}

.btn-custom .btn-primary {
    background: #00114f;
    border: 1px solid #00114f;
}

.panel-custom {
    background: #00081b;
}

.panel-custom .panel-footer {
    border-top: 1px solid #00114f;
    background: #00081b;
}

@media screen and (max-width: 768px) {
    .row-offcanvas {
        position: relative;
        -webkit-transition: all 0.1s ease-out;
        -moz-transition: all 0.1s ease-out;
        transition: all 0.1s ease-out;
    }

    .row-offcanvas-right
    .sidebar-offcanvas {
        left: -50%; /* 6 columns */
    }

    .row-offcanvas-left
    .sidebar-offcanvas {
        right: -50%; /* 6 columns */
    }

    .row-offcanvas-right.active {
        left: 50%; /* 6 columns */
    }

    .row-offcanvas-left.active {
        right: 50%; /* 6 columns */
    }

    .sidebar-offcanvas {
        position: absolute;
        top: 0;
        width: 50%; /* 6 columns */
    }
}

.new-stream {
    display: none
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}

.main-nav nav {
    margin: 0;
    background: none;
    border: none;
}

.main-nav .nav li a {
    color: #fff;
}

.main-nav .nav li a:hover {
    color: #fff;
    background: #11aa1b;
}

.main-nav .nav li.active a,
.main-nav .nav li.active a:hover {
    color: #fff;
    background: #11aa1b;
}

.main-nav .dropdown-menu {
    background: #00114f;
}

.main-nav .open a.dropdown-toggle {
    background: #11aa1b !important;
    color: #fff !important;
}

.main-nav a.navbar-brand,
.main-nav a.navbar-brand:hover {
    color: #fff;
}

.main-nav .dropdown-toggle .caret {
    border-top-color: #fff !important;
    border-bottom-color: #fff !important;
}

.upload-hand {
    height: 150px;
    border: 1px dashed #ddd;
    border-radius: 5px;
    text-align: center;
    padding-top: 40px;
    padding: 20px 10px;
}

.hand-thumbs {
    margin-bottom: 15px;
    text-align: center;
}

.hand-thumbs img {
    width: 120px;
    margin: 0 5px;
}

.small-logo.visible-xs {
    padding: 10px;
}

.row .tel-top {
    padding: 5px 0;
}

.top-btn {
    padding: 10px 0 20px 0;
    text-align: center;
}

.page_content {
    padding: 20px 0;
}

.body_illustration {
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.body_inner {
    width: 165px;
    margin: 0 auto;
    position: relative;
}

.should_width {
    /*top: 103px;*/
    top: -300px;
    left: 21px;
    width: 123px;
}

.body_width {
    /*top: 155px;*/
    top: -300px;
    left: 9px;
    width: 147px;
    overflow: hidden;
    position: relative;
}

.hoz_measure {

    height: 5px;
    background: #fff;
    position: absolute;
    border-radius: 2px;
    /*border-top: 1px solid #000;
    border-bottom: 1px solid #000;*/
    box-shadow: -1px 1px 3px #000;
    display: none;
    overflow: hidden;
}

.ruler-row {
    float: left;
}

.tall {
    width: 0;
    height: 3px;
    border: 1px solid #000;
    margin: 0 5px;
    float: left;
}

.mid {
    width: 0;
    height: 2px;
    border: 1px solid #000;
    margin: 0 1px;
    float: left;
}

#map {
    color: #000;
}

.top-border {
    height: 5px;
    background: #000;
}